// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'

// Constants
import { ActionFields } from '@/Constants/Global/ActionFields'
import { AppValues }    from '@/Constants/Global/AppValues'
import { Module4  }     from '@/Constants/Modules/Module4'

// Store
import Store from '@/Store/Global/Default'

// Namespace Export
export namespace View41DataParsers {
	export function AddonsParser(data: Array<any>, includeStorages = true, includeActions = true) {
		// Clase Constructora.
		const bm = new BuilderManager(data, includeActions ? ActionFields.Edit : undefined)
		const { fields, items, actions } = bm.getElements()

		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields(fields, ['_idCheckListAddons', '_idStorages'])

		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(fields, new FieldsOptions()
			.add('addonCode', 'Código').isSortable().setAlign(null, 'center').setSearchKey('code')
			.add('addonName', 'Descripción').isSortable().setSearchKey('name')
			.add('addonTypeEquipment', 'Tipo Equipo').isSortable().setAlign(null, 'center').setSearchKey('typeEquipment')
			.add('addonGroupArticle', 'Grupo Articulo').isSortable().setAlign(null, 'center').setSearchKey('groupArticle')
			.add('addonAttributes', 'Preguntas')
			.add('storages', 'Plantas')
			.add('addonIsForClient', 'Exclusivo Cliente').setAlign(null, 'center')
		)

		// Eliminar los campos que no queremos mostrar en la tabla.
		if (!includeStorages) bm.removeFields(fields, ['storages'])

		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields(fields, [
			'num', 'addonCode', 'addonName', 'addonTypeEquipment', 'addonGroupArticle', 'addonAttributes', 'storages', 'isForClient'
		])

		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(items, new ItemMissingFields()
			.add('num', { $text: 'AUTO_INCREMENT' })
			.add('storages')
		)

		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters(items, {
			addonsAttributes: (values: Array<string>) => {
				return values.sort()
			},

			addonGroupArticle: (value: string) => {
				return value === Module4.M40.Defaults.TypeActiveNames.FACILITY
					? Module4.M40.Defaults.TypeActiveTranslationNames.FACILITY.toUpperCase()
					: value
			},

			addonIsForClient: (value: boolean) => {
				return value ? 'Sí' : 'No'
			},

			storages: (value: any) => {
				return value.length > 0 ? 
				value.map((item: any) =>  item.storageName ) : [AppValues.Strings.DEFAULT_EMPTY_STRING];
			},
		})

		// Retornar Objeto con los Campos, Items y Acciones.
		return { fields, items, actions }
	}

	export function AddonsAssociationsParser(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data)
		const { fields, items } = bm.getElements()

		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields(fields, ['_idCheckListAddons', '_idStorage','_idCompany'])
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(fields, new FieldsOptions()
			.add('addonCode', 'Código').isSortable().setAlign('center', 'center')
			.add('addonName', 'Descripción').isSortable().isSortable().setAlign('center', 'center')
			.add('addonTypeEquipment', 'Tipo Equipo').isSortable().isSortable().setAlign('center', 'center')
			.add('addonGroupArticle', 'Grupo Articulo').isSortable().isSortable().setAlign('center', 'center')
			.add('storageCode', 'Código Planta').isSortable().isSortable().setAlign('center', 'center')
			.add('storageName', 'Planta').isSortable().isSortable().setAlign('center', null)
			.add('companyName', 'Cliente').isSortable().isSortable().setAlign('center', null)
			.add('addonAttributes', 'Listado Evaluación').isSortable().isSortable().setAlign('center', null)
			.add('addonIsForClient', '¿Exclusivo Cliente?').isSortable().isSortable().setAlign('center', 'center')
		)
	
		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields(fields, [
			'addonCode', 'addonName', 'addonTypeEquipment', 'addonGroupArticle', 'storageCode', 'storageName', 'companyName', 'addonAttributes', 'addonIsForClient'
		])

		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters(items, {
			addonIsForClient: (value: boolean) => {
				return value ? 'Sí' : 'No'
			},

			companyName: (value: string) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageCode: (value: string) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageName: (value: string) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return { fields, items }
	}

	export function FacilitiesParser(data: Array<any>, includeActions = true) {
		// Clase Constructora.
		const bm = new BuilderManager(data, includeActions ? ActionFields.Edit : undefined)
		const { fields, items, actions } = bm.getElements()
	
		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields(fields, [
			'_idInstallation', 'typeActive', 'groupArticle'
		])
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(fields, new FieldsOptions()
			.add('code', 'Código Instalación').isSortable().setAlign('center', 'center')
			.add('name', 'Descripción').isSortable().setAlign('center', 'center')
			//.add('groupArticle', 'Grupo Articulo').isSortable().setAlign('center', 'center')
			.add('storages', 'Plantas').isSortable()
			.add('isValid', '¿Esta Activa?').setAlign('center', 'center')
		)
	
		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields(fields, [
			'code', 'name', 'storages', 'isValid'
		])
	
		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(items, new ItemMissingFields()
			.add('storages')
		)
	
		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters(items, {
			storages: (value: any) => {
				return value.length > 0 ? 
				value.map((item: any) =>  item.storageName ) : [AppValues.Strings.DEFAULT_EMPTY_STRING];
			},

			groupArticle: (v: string) => {
				return v === AppValues.ArticleGroups.FACILITY ? 'Instalación' : v
			},
	
			isValid: (v: boolean) => {
				return v ? 'Sí' : 'No'
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return { fields, items, actions }
	}

	export function FacilityAssociationsParser(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data)
		const { fields, items } = bm.getElements()

		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields(fields, [
			'_idInstallation', '_idStorage', '_idCompany', 'storageCommune'
		])
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(fields, new FieldsOptions()
			.add('installationCode', 'Código').isSortable().setAlign('center', 'center').setSearchKey('code')
			.add('installationName', 'Descripción').isSortable().isSortable().setAlign('center', 'center').setSearchKey('name')
			.add('installationGroupArticle', 'Tipo Activo').isSortable().isSortable().setAlign('center', 'center').setSearchKey('groupArticle')
			.add('storageCode', 'Código Planta').isSortable().isSortable().setAlign('center', 'center').setSearchKey('storage.code')
			.add('storageName', 'Planta').isSortable().isSortable().setAlign('center', 'center').setSearchKey('storage.name')
			.add('companyName', 'Cliente').isSortable().isSortable().setAlign('center', 'center').setSearchKey('company.name')
			.add('storageAddress', 'Dirección').isSortable().isSortable().setAlign('center', 'center').setSearchKey('storage.address.name')
			.add('installationIsValid', '¿Esta Activa?').isSortable().isSortable().setAlign('center', 'center')
		)
	
		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields(fields, [
			'installationCode', 'installationName', 'installationGroupArticle', 'storageCode', 'storageName', 'companyName', 'storageAddress', 'installationIsValid'
		])

		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters(items, {
			installationGroupArticle: (value: string) => {
				if (!value) return AppValues.Strings.DEFAULT_EMPTY_STRING
				const M40Defaults = Module4.M40.Defaults
				return value.toUpperCase() === M40Defaults.TypeActiveNames.FACILITY.toUpperCase() ? M40Defaults.TypeActiveTranslationNames.FACILITY : value
			},

			installationIsValid: (value: boolean) => {
				return value ? 'Sí' : 'No'
			},

			storageAddress: (value: boolean) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return { fields, items }
	}
}