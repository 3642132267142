// ./
import { View41DataParsers } from './response.parser'

// Classes
import { JoiManager }       from '@/Classes/Network/JoiManager'
import { DataParsers }      from '@/Classes/Responses/DataParsers'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Components
import BasicHeader  from '@/Components/Global/BasicHeader/template.vue'
import DataTable    from '@/Components/Global/DataTable/template.vue'
import MenuBar      from '@/Components/Global/MenuBar/template.vue'
import PopupTable   from '@/Components/Global/PopupTable/template.vue'
import SideMenu     from '@/Components/Global/SideMenu/template.vue'
import AddonForm    from '@/Components/Modules/4/AddonForm/template.vue'
import AddonSetup   from '@/Components/Modules/4/AddonSetup/template.vue'
import FacilityForm from '@/Components/Modules/4/FacilityForm/template.vue'

// Component (Refs)
import { DataTableRef }    from '@/Components/Global/DataTable/component'
import { MenuBarRef }      from '@/Components/Global/MenuBar/component'
import { PopupTableRef }   from '@/Components/Global/PopupTable/component'
import { SideMenuRef }     from '@/Components/Global/SideMenu/component'
import { AddonFormRef }    from '@/Components/Modules/4/AddonForm/component'
import { AddonSetupRef }   from '@/Components/Modules/4/AddonSetup/component'
import { FacilityFormRef } from '@/Components/Modules/4/FacilityForm/component'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Component } from '@/Constants/Global/Component'
import { Server }    from '@/Constants/Global/Server'
import { VueRouter } from '@/Constants/Global/VueRouter'
import { Module4 }   from '@/Constants/Modules/Module4'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase      from '@/Mixins/MixinBase'
import MixinComponent from '@/Mixins/MixinComponent'
import MixinFetch     from '@/Mixins/MixinFetch'

// Store
import Store from '@/Store/Global/Default'

// Views
import { parseStoragesResponse, parseStackedStoragesResponse } from '@/Views/Modules/2/22/response.parser'

// Component Extend
const View41 = VueMixins(MixinBase, MixinComponent, MixinFetch).extend({
	name: VueRouter.Modules.View41.NAME,

	components: {
		AddonForm,
		AddonSetup,
		BasicHeader,
		DataTable,
		FacilityForm,
		MenuBar,
		PopupTable,
		SideMenu
	},

	data: function() {
		return {
			states: {
				associationInputPlaceHolder: '',
				associationSelection: null,
				associationValue: '',
				componentName: 'Addons',
				componentSubName: 'Clients',
				showAddonForm: false,
				showAddonSetup: false,
				showDataTable: true,
				showFacilityForm: false,
				showInputButton: true,
				showMenuBar: false,
				showPopupTable: false
			}
		}
	},

	mounted: function() {
		this._initComponents()
		this._initPermissions()

		// Deshabilitar Ciertos Parametros del componente 'DataTable'.
		this._dataTable.setStates<DataTableRef['states']>({ showRefreshButton: false })

		// Deshabilitar comportamiento de Busqueda Local para 'DataTable'.
		this._dataTable.setStates<DataTableRef['states']>({ isLocalSearch: false })
		this._popupTable._dataTable.setStates<DataTableRef['states']>({ isLocalSearch: false })

		// Establecer los elementos del componente 'MenuBar'.
		this._menuBar.setItems(Module4.M41.Collections.MenuBarAssociationsOptions)
		this._menuBar.setStates<MenuBarRef['states']>({ selected: Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE })

		// Establecer las propiedades para el componente 'PopupTable/DataTable'.
		this._popupTable._dataTable.setStates<DataTableRef['states']>({ showExportButtons: false, showRefreshButton: false }) 
		this._popupTable._dataTable.setSelectable(true)

		// Establecer las propiedades para el componente 'SideMenu'.
		this._sideMenu.initialize(Module4.M41.Components.SideMenu.Initialization)

		// Establecer Altura Maxima de los componentes 'DataTable'.
		this._addonSetup._dataTable.setStates<DataTableRef['states']>({ showExportButtons: false, showRefreshButton: false })
	},

	computed: {
		_addonForm: function(): AddonFormRef {
			return this.$refs.addonForm as AddonFormRef
		},

		_addonSetup: function(): AddonSetupRef {
			return this.$refs.addonSetup as AddonSetupRef
		},

		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_facilityForm: function(): FacilityFormRef {
			return this.$refs.facilityForm as FacilityFormRef
		},

		_menuBar: function(): MenuBarRef {
			return this.$refs.menuBar as MenuBarRef
		},

		_popupTable: function(): PopupTableRef {
			return this.$refs.popupTable as PopupTableRef
		},

		_sideMenu: function(): SideMenuRef {
			return this.$refs.sideMenu as SideMenuRef
		}
	},

	methods: {
		_clearComponents: function() {
			this._addonForm.clear()
			this._addonSetup.clear()
			this._facilityForm.clear()
			this._menuBar.setStates<MenuBarRef['states']>({ selected: Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE })
		},

		_fetchAddonsAssociations: async function(page: number, idStorage?: string, idAddon?: string) {
			const { _idAdminCompany } = Store.getters.getStoredUser
			const _idStorage = idStorage || 'null'
			const _idAddon   = idAddon   || 'null'
			
			// Realizar la Petición al servidor.
			const params = { _idAdminCompany, _idAddon, _idStorage, page, itemsPerPage: this._dataTable.itemsPerPage }
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.CheckLists.GetCheckListsAddonsByDefaultAssociation, params })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				return response.data.body[0]
			}
		},

		_fetchAssociationsByFilter: async function() {
			const { associationSelection, componentName } = this.states
			const { selected } = this._menuBar.states

			// Si no existe una selección, finalizar proceso.
			if ((!associationSelection) && selected !== Module4.M41.Defaults.MenuBarKeyAssociationsOptions.EVERYTHING) return

			// Formularios (SideMenu).
			if (componentName === 'Addons') {
				// Código (MenuBar).
				if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE) {
					
					const addons = Store.getters.getStoredAddonsAssociationsForPage(1);
					const { fields, items } = View41DataParsers.AddonsAssociationsParser(
  						addons.filter((elemento:any) => elemento.addonCode === associationSelection.addonCode)
					);
					this._dataTable.updateElementsAndPagination(1, fields, items)
				}

				// Ubicaciones (MenuBar).
				else if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.STORAGES) {
					// Realizar la Petición al servidor.
					const response = await this._fetchAddonsAssociations(1, associationSelection?._idStorage, null)
					
					// Mostrar la información en la tabla (Si existen registros).
					if (response.totalPages > 0) {
						const { fields, items } = View41DataParsers.AddonsAssociationsParser(response.data)
						this._dataTable.updateElementsAndPagination(response.totalPages, fields, items)
					}

					// De lo contrario, vaciar la Tabla Principal.
					else {
						this._dataTable.clearAll()
						this.showToast('Ningun Registro Encontrado', `No se encontraron registros para la Planta '${ associationSelection.name }'.`, 'warning')

						// Volver a Recargar los Registros sin filtros.
						this._initComponents()
					}
				}

				// Todo (MenuBar).
				else if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.EVERYTHING) {

				}
			}

			// Instalaciones (SideMenu).
			else if (componentName === 'Facilities') {
				// Código (MenuBar).
				if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE) {

				}

				// Ubicaciones (MenuBar).
				else if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.STORAGES) {
					// Realizar la Petición al servidor.
					const response = await this._fetchFacilityAssociations(1, associationSelection?._idStorage, null)
					
					// Mostrar la información en la tabla (Si existen registros).
					if (response.totalPages > 0) {
						const { fields, items } = View41DataParsers.FacilityAssociationsParser(response.data)
						this._dataTable.updateElementsAndPagination(response.totalPages, fields, items)
					}

					// De lo contrario, vaciar la Tabla Principal.
					else {
						this._dataTable.clearAll()
						this.showToast('Ningun Registro Encontrado', `No se encontraron registros para la Planta '${ associationSelection.name }'.`, 'warning')

						// Volver a Recargar los Registros sin filtros.
						this._initComponents()
					}
				}

				// Todo (MenuBar).
				else if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.EVERYTHING) {

				}
			}
		},

		_fetchFacilityAssociations: async function(page: number, idStorage?: string, idInstallation?: string) {
			const { _idAdminCompany } = Store.getters.getStoredUser
			const _idStorage          = idStorage        || 'null'
			const _idInstallation     = idInstallation   || 'null'
			
			// Realizar la Petición al servidor.
			const params = { _idAdminCompany, _idInstallation, _idStorage, page, itemsPerPage: this._dataTable.itemsPerPage }
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.CheckLists.GetInstallationAssociated, params })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				return response.data.body[0]
			}
		},

		_fetchAddons: async function(page: number, isForClient: boolean) {
			// Realizar la petición al Servidor.
			const { _idAdminCompany } = Store.getters.getStoredUser
			const params = { _idAdminCompany, itemsPerPage: this._dataTable.itemsPerPage, page, isForClient}
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.CheckLists.GetCheckListsAddons, params })
			
			//Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				return response.data.body[0]
			}	
		}, 

		_fetchAddonsFull: async function(page: number) {
			// Realizar la petición al Servidor.
			const { _idAdminCompany } = Store.getters.getStoredUser
			const params = { _idAdminCompany, itemsPerPage: this._dataTable.itemsPerPage, page}
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.CheckLists.GetFullAddons, params })
			
			//Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				return response.data.body[0]
			}	
		}, 

		_fetchInstallations: async function(page: number) {
			// Realizar la petición al Servidor.
			const { _idAdminCompany } = Store.getters.getStoredUser
			const params = { _idAdminCompany, itemsPerPage: this._dataTable.itemsPerPage, page }
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.CheckLists.GetInstallations, params })
			
			//Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				return response.data.body[0]
			}	
		}, 

		_initComponents: function() {
			this._initDataTable(this._menuBar.states.selected)
		},

		_initDataTable: async function(key: number) {
			const { componentName, componentSubName } = this.states
			const ByStorage = componentSubName === 'ByStorage'

			// Formularios.
			if (componentName === 'Addons') {
				const clientAddons = componentSubName === 'Clients'
				const defaultAddons = componentSubName === 'ByDefault'

				// Actualizar ciertos parametros para la 'Asignación por Ubicación'.
				if (ByStorage) {
					// Siempre ejecuta el fetch
					const response = await this._fetchAddonsAssociations(1, null, null)
					const data = Array.isArray(response) ? response : response.data
					Store.commit('storeAddonsAssociationsForPage', { data, page: 1, totalPages: response.totalPages })

					// Inicialización de los datos del 'DataTable'.
					const addons = Store.getters.getStoredAddonsAssociationsForPage(1)
					const { fields, items } = View41DataParsers.AddonsAssociationsParser(addons)
					this._dataTable.updateElementsAndPagination(response.totalPages, fields, items)
				}
				else {
					// Limpia el Store al cambio de Form.
					Store.commit('destroyAddons')

					// Se define parametro para isForClient.
					const params = clientAddons ? true : (defaultAddons ? false : null)

					// Realizar la Petición al servidor.
					const response = await this._fetchAddons(1,params)
					const data = Array.isArray(response) ? response : response.data
					Store.commit('storeAddonsForPage', {data, page: 1, totalPages: response.totalPages})

					// Inicialización de los datos del 'DataTable'.
					const addons = Store.getters.getStoredAddonsForPage(1)
					const { fields, items, actions } = View41DataParsers.AddonsParser(addons, clientAddons)
					this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
				}
			}

			// Instalaciones.
			else if (componentName === 'Facilities') {
				// Actualizar ciertos parametros para la 'Asignación por Ubicación'.
				if (ByStorage) {
					const response = await this._fetchFacilityAssociations(1)
					const data = Array.isArray(response) ? response : response.data
					Store.commit('storeFacilitiesAssociationsForPage', { data, page: 1, totalPages: response.totalPages })
				
					// Inicialización de los datos del 'DataTable'.
					const addons = Store.getters.getStoredFacilitiesAssociationsForPage(1)
					const { fields, items } = View41DataParsers.FacilityAssociationsParser(addons)
					this._dataTable.updateElementsAndPagination(response.totalPages, fields, items)
				}
				else {
					// Realizar la Petición al servidor.
					const response = await this._fetchInstallations(1)
					const data = Array.isArray(response) ? response : response.data
					Store.commit('storeInstallationsForPage', { data, page: 1, totalPages: response.totalPages })

					// Inicialización de los datos del 'DataTable'.
					const facility = Store.getters.getStoredInstallationsForPage(1)
					const { fields, items, actions } = View41DataParsers.FacilitiesParser(facility)
					this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
				}
			}
		},

		_initPermissions: function() {
			this._dataTable.setPermission('ACTION_EDIT', true)
			this._dataTable.setPermission('NEW_BUTTON', true)
		},

		_onShowPopupTable: async function() {
			const { componentName } = this.states
			const { selected } = this._menuBar.states

			// Formularios.
			if (componentName === 'Addons') {
				if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE) {
					// Respuesta de la Acción donde se obtienen los Registros.
					const response = await this._fetchAddonsFull(1)
					const data = Array.isArray(response) ? response : response.data
					Store.commit('storeAddonsForPage', {data, page:1, totalPages: response.totalPages})
	
					// Inicialización de los datos del 'DataTable'.
					const addons = Store.getters.getStoredAddonsForPage(1)
					const { fields, items } = View41DataParsers.AddonsParser(addons, false, false)
					this._popupTable._dataTable.setElements(fields, items)

					// Dejar por defecto seleccionada el Filtro por 'Código Formulario'.
					this._popupTable._dataTable.sortOrder('addonCode', 'asc')
				}
			}

			// Instalaciones.
			else if (componentName === 'Facilities') {
				if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE) {
					// Respuesta de la Acción donde se obtienen los Registros.
					const response = await this._fetchInstallations(1)
					Store.commit('storeInstallationsForPage', { data: response.data, page: 1, totalPages: response.totalPages })

					// Inicialización de los datos del 'DataTable'.
					const facilities = Store.getters.getStoredInstallationsForPage(1)
					const { fields, items } = View41DataParsers.FacilitiesParser(facilities, false)
					this._popupTable._dataTable.updateElementsAndPagination(response.totalPages, fields, items)

					// Dejar por defecto seleccionada el Filtro por 'Código Formulario'.
					this._popupTable._dataTable.sortOrder('code', 'asc')
				}
			}

			// Valido para ambos casos.
			if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.STORAGES) {
				// Respuesta de la Acción donde se obtienen los Registros.
				const response = await Store.dispatch(`fetchStoragesForPage`, { forceRefresh: true, page: 1 })

				// Procesar la información y actualizar el Componente.
				const SummaryParser = DataParsers.Storages.GetSummaryParser(this._popupTable._dataTable.states.stacked)
				const { fields, items } = SummaryParser(response.data)
				this._popupTable._dataTable.updateElementsAndPagination(response.totalPages, fields, items)

				// Dejar por defecto seleccionada el Filtro por 'Código Formulario'.
				this._popupTable._dataTable.sortOrder('code', 'asc')
			}
			
			// Mostrar componente 'PopupTable'.
			this.setStates<View41Ref['states']>({ showPopupTable: true })
		},

		_parsePopupTableOnMenuBarSelection: function(selected: number) {
			// Determinar si se estan administrando Formularios o Instalaciones.
			const { componentName } = this.states

			// Formularios.
			if (componentName === 'Addons') {
				if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE) {
					this._popupTable.setStates<PopupTableRef['states']>({ title: 'Formulario CheckList' })
					this.setStates<View41Ref['states']>({ associationInputPlaceHolder: 'Seleccionar Formulario' })
					return
				}
			}

			// Instalaciones.
			else if (componentName === 'Facilities') {
				if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE) {
					this._popupTable.setStates<PopupTableRef['states']>({ title: 'Instalaciones' })
					this.setStates<View41Ref['states']>({ associationInputPlaceHolder: 'Seleccionar Instalación' })
					return
				}
			}

			// Valido para ambos casos.
			if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.STORAGES) {
				this._popupTable.setStates<PopupTableRef['states']>({ title: 'Plantas' })
				this.setStates<View41Ref['states']>({ associationInputPlaceHolder: 'Seleccionar Planta' })
			}
		},

		_resetAllDataAndValues: function() {
			// Restablecer los parametros de varios componentes.
			this._dataTable.clearAll()
			this._addonSetup._dataTable.clearAll()
			this._initComponents()
			this._clearComponents()

			// Reiniciar la visibilidad de los componentes.
			this.setStates<View41Ref['states']>({
				showAddonForm: false,
				showAddonSetup: false,
				showDataTable: true,
				showFacilityForm: false
			})

			// Mostrar el componente 'MenuBar' solo para los formularios de clientes.
			const { componentName, componentSubName } = this.states
			const showMenuBar = componentName === 'Addons' && componentSubName === 'Clients'
			this._addonSetup.setStates<AddonSetupRef['states']>({ showMenuBar })
		},

		_setMenuBarStoragesAsDefault: function() {
			const storagesSelection = Module4.M41.Defaults.MenuBarKeyOptions.STORAGES
			this._addonSetup._menuBar.setStates<MenuBarRef['states']>({ selected: storagesSelection })
			this._addonSetup.updateDataTableControls(storagesSelection)
			this._addonSetup.updateDataTableWrapperElements(storagesSelection)
		},

		_toggleComponents: function(showDataTable: boolean) {
			const { componentName, componentSubName } = this.states
			const whichForm = componentName === 'Addons' ? { showAddonForm: !showDataTable } : { showFacilityForm: !showDataTable }
			this.setStates<View41Ref['states']>({ ...whichForm, showAddonSetup: !showDataTable, showDataTable })
		},

		_upsertAddon: async function() {
			const { _id, action, items, storages }  = this._addonSetup.states
			const { states } = this._addonForm
			const user = Store.getters.getStoredUser
			const isUpdating = action === Component.Actions.UPDATE

			// Datos requeridos para crear un nuevo CheckListAddons.
			const { _idAdminCompany } = user
			const { addonCode, addonName, groupArticle, isForClient, typeEquipment } = states
			const _idStorages: Array<string> = isForClient ? storages.map((x: any) => x._idStorage) : []

			// Objeto con las Propiedades requeridas por la Petición.
			const body: any = {
				_idAdminCompany, _idStorages, groupArticle, typeEquipment, isForClient,
				code: addonCode,
				name: addonName,
				attributes: items.map((x) => x.name)
			}

			// Actualizar el objeto 'body' si se esta actualizando el formulario.
			if (isUpdating) {
				body._idCheckListAddons = _id
				delete body._idAdminCompany
			}

			// Validación de los campos de la petición.
			const joiSchema = isUpdating ? Module4.M41.JoiSchemas.UpdateCheckListAddon : Module4.M41.JoiSchemas.AddCheckListAddon
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, `Error al ${ isUpdating ? 'actualizar' : 'crear' } el Formulario de Evaluación`, result.error)

			// Realizar la Petición al servidor.
			const actionMethod = isUpdating ? Server.Fetching.Method.PATCH : Server.Fetching.Method.POST
			const path = isUpdating ? Server.Routes.CheckLists.UpdateCheckListAddons : Server.Routes.CheckLists.AddCheckListAddons
			const response = await this.doFetch({ action: actionMethod, path, body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Respuesta de la Petición.
				const _response  = response.data.body
				const commitName = isUpdating ? 'updateAddon' : 'addAddon'
				const titleText  = isUpdating ? 'Actualización' : 'Creación'
				const actionText = isUpdating ? 'actualizado' : 'creado'
				Store.commit(commitName, _response[0])
				this.showToast(`${ titleText } de Registro`, `El Formulario de Evaluación a sido ${ actionText } correctamente!`, 'success')

				// Restablecer todas las propiedades y variables.
				this._resetAllDataAndValues()
			}
		},

		_upsertFacility: async function() {
			const { _id, action, storages }  = this._addonSetup.states
			const { states } = this._facilityForm
			const user = Store.getters.getStoredUser
			const isUpdating = action === Component.Actions.UPDATE

			// Datos requeridos para crear una nueva instalación.
			const _idResponsible = user._idUser
			const _idStorages: Array<string> = storages.map((x: any) => x._idStorage)
			const { code, name } = states

			// Objeto con las Propiedades requeridas por la Petición.
			const body: any = {
				_idResponsible, _idStorages, code, name
			}

			// Actualizar el objeto 'body' si se esta actualizando la Instalación.
			if (isUpdating) {
				body._idInstallation = _id
				body.isValid = true
			}

			// Validación de los campos de la petición.
			const joiSchema = isUpdating ? Module4.M41.JoiSchemas.UpdateInstallation : Module4.M41.JoiSchemas.AddInstallation
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, `Error al ${ isUpdating ? 'actualizar' : 'crear' } el Formulario de Evaluación`, result.error)

			// Realizar la Petición al servidor.
			const actionMethod = isUpdating ? Server.Fetching.Method.PATCH : Server.Fetching.Method.POST
			const path = isUpdating ? Server.Routes.CheckLists.UpdateInstallation : Server.Routes.CheckLists.AddInstallation
			const response = await this.doFetch({ action: actionMethod, path, body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Respuesta de la Petición.
				const _response  = response.data.body
				const commitName = isUpdating ? 'updateInstallation' : 'addInstallation'
				const titleText  = isUpdating ? 'Actualización' : 'Creación'
				const actionText = isUpdating ? 'actualizado' : 'creado'
				Store.commit(commitName, _response[0])
				this.showToast(`${ titleText } de Registro`, `La Instalación a sido ${ actionText } correctamente!`, 'success')

				// Restablecer todas las propiedades y variables.
				this._resetAllDataAndValues()
			}
		},

		/* <=================|=============================|==================> */
		/* <=================| EVENT DECLARATION FUNCTIONS |==================> */
		/* <=================|=============================|==================> */

		onASButtonClick: function(key: string) {
			if (key === 'submit') {
				switch (this.states.componentName) {
					case 'Addons'    : return this._upsertAddon()
					case 'Facilities': return this._upsertFacility()
				}
			}
			if (key === 'close') {
				this._resetAllDataAndValues()
			}
		},

		onButtonClick: function(_: never, componentName: string) {
			const componentSubName = componentName === 'Addons' ? 'Clients' : 'List'
			this.setStates<View41Ref['states']>({ componentName, componentSubName, showMenuBar: false })
			// this._sideMenu.setParams(componentName, componentSubName)
			this._resetAllDataAndValues()
		},

		onDTButtonClick: function(key: string, { item }: any) {
			if (key === 'edit') {
				const { componentName } = this.states
				const isAddons = componentName === 'Addons'
				const isFacilities = componentName === 'Facilities'

				// Completar los parametros para el 'AddonForm'.
				if (isAddons) {
					this._addonForm.setStates<AddonFormRef['states']>({
						addonCode: item.code,
						addonName: item.name,
						groupArticle: item.groupArticle,
						isForClient: item.isForClient === 'Sí',
						typeEquipment: item.typeEquipment
					})
				}

				// Completar los parametros para el 'FacilityForm'.
				else if (isFacilities) {
					this._facilityForm.setStates<FacilityFormRef['states']>({
						code: item.code,
						name: item.name
					})
				}

				// Las Ubicaciones vienen de diferente manera en los Addons e Instalaciones.
				let storages = []
				if (isAddons) storages = item._idStorages?.map((x: any) => Store.getters.getStoredStorageById(x))
				else if (isFacilities) storages = item.storages?.map((x: any) => Store.getters.getStoredStorageById(x._idStorage))

				// Propiedades a actualizar del estado del 'AddonSetup'.
				const addonSetupStates: any = {
					action: Component.Actions.UPDATE, storages
				}

				// Se esta actualizando un Formulario.
				if (isAddons) {
					addonSetupStates._id = item._idCheckListAddons
					addonSetupStates.items = item.attributes.map((x: string) => ({ name: x }))
				}

				// Se esta actualizando una Instalación.
				else if (isFacilities) {
					addonSetupStates._id = item._idInstallation
				}

				// Rellenar los datos del componente 'AddonSetup'.
				this._addonSetup.setStates<AddonSetupRef['states']>(addonSetupStates)

				// Por defecto la opción 'Evaluaciones' es la predeterminda.
				this._addonSetup.parseItems()
				if (isFacilities) this._addonSetup.parseStorages()

				// Actualizar la visibilidad de los componentes.
				if (isFacilities) this._setMenuBarStoragesAsDefault()
				this._toggleComponents(false)
			}
		},

		onDTNewButtonClick: function() {
			const { componentName, componentSubName } = this.states
			switch (componentName) {
				case 'Addons':
					this._addonForm.setStates<AddonFormRef['states']>({ isForClient: componentSubName === 'Clients' })
					break
				case 'Facilities':
					this._setMenuBarStoragesAsDefault()
					break

			}
			this._toggleComponents(false)
		},

		onDTPaginationChanged: async function(page: number) {
			// La Pestaña actual activa.
			const { componentName, componentSubName } = this.states

			// Formularios.
			if (componentName === 'Addons') {
				const clientAddons = componentSubName === 'Clients'
				const defaultAddons = componentSubName === 'ByDefault'
				const ByStorage = componentSubName === 'ByStorage'

				if (ByStorage) {
					const totalPages = Store.getters.getStoredAddonsAssociationsTotalPages(page)
					const responseGetAddons = Store.getters[`getStoredAddonsAssociationsForPage`](page) as Array<any>

					// Verifica si los datos existen en Store
					if (responseGetAddons) {
						const { fields, items } = View41DataParsers.AddonsAssociationsParser(responseGetAddons)
						this._dataTable.updateElementsAndPagination(totalPages, fields, items)
						return
					}

					const response = await this._fetchAddonsAssociations(page, null, null)
					const data = Array.isArray(response) ? response : response.data
					Store.commit('storeAddonsAssociationsForPage', { data, page, totalPages: response.totalPages })

					// Inicialización de los datos del 'DataTable'.
					const { fields, items } = View41DataParsers.AddonsAssociationsParser(data)
					this._dataTable.updateElementsAndPagination(response.totalPages, fields, items)
				}
				else {
					const params = clientAddons ? true : (defaultAddons ? false : null)
					const totalPages = Store.getters.getStoredAddonsTotalPages(page)
					const responseGetAddons = Store.getters[`getStoredAddonsForPage`](page) as Array<any>

					// Verifica si los datos existen en Store
					if (responseGetAddons) {
						const { fields, items, actions } = View41DataParsers.AddonsParser(responseGetAddons, clientAddons)
						this._dataTable.updateElementsAndPagination(totalPages, fields, items, actions)
						return
					}

					// Realizar la Petición al servidor.
					const response = await this._fetchAddons(page, params)
					const data = Array.isArray(response) ? response : response.data
					Store.commit('storeAddonsForPage', { data, page, totalPages: response.totalPages })

					// Inicialización de los datos del 'DataTable'.
					const { fields, items, actions } = View41DataParsers.AddonsParser(data, clientAddons)
					this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
				}
			}
			else if (componentName == 'Facilities') {
				const ByStorage = componentSubName === 'ByStorage'

				if (ByStorage) {
					const totalPages = Store.getters.getStoredFacilitiesAssociationsTotalPages(page)
					const responseGetAddons = Store.getters[`getStoredFacilitiesAssociationsForPage`](page) as Array<any>

					// Verifica si los datos existen en Store
					if (responseGetAddons) {
						const { fields, items } = View41DataParsers.FacilityAssociationsParser(responseGetAddons)
						this._dataTable.updateElementsAndPagination(totalPages, fields, items)
						return
					}

					const response = await this._fetchFacilityAssociations(page)
					const data = Array.isArray(response) ? response : response.data
					Store.commit('storeFacilitiesAssociationsForPage', { data, page, totalPages: response.totalPages })

					// Inicialización de los datos del 'DataTable'.
					const { fields, items } = View41DataParsers.FacilityAssociationsParser(data)
					this._dataTable.updateElementsAndPagination(response.totalPages, fields, items)

				}
				else {
					const totalPages = Store.getters.getStoredInstallationsTotalPages(page)
					const responseGetListFacility = Store.getters[`getStoredInstallationsForPage`](page) as Array<any>

					// Verifica si los datos existen en Store
					if (responseGetListFacility) {
						const { fields, items, actions } = View41DataParsers.FacilitiesParser(responseGetListFacility)
						this._dataTable.updateElementsAndPagination(totalPages, fields, items, actions)
						return
					}

					// Realizar la Petición al servidor.
					const response = await this._fetchInstallations(page)
					const data = Array.isArray(response) ? response : response.data
					Store.commit('storeInstallationsForPage', { data, page, totalPages: response.totalPages })

					// Inicialización de los datos del 'DataTable'.
					const { fields, items, actions } = View41DataParsers.FacilitiesParser(data)
					this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
				}
			}
		},

		onDTSearchButtonClicked: async function (searching: any) {
			const { searchKey, searchValue } = searching
			const { _idAdminCompany } = Store.getters.getStoredUser
			const { componentName, componentSubName } = this.states
			const { selected } = this._menuBar.states

			// Formularios.
			if (componentName === 'Addons') {
				const clientAddons = componentSubName === 'Clients'
				const defaultAddons = componentSubName === 'ByDefault'

				if (clientAddons) {
					this._dataTable.doInputSearch(Server.Routes.CheckLists.GetAllAddonsForFilter,
						View41DataParsers.AddonsParser,
						{ _idAdminCompany, isForClient: true, searchKey, searchValue }
					)
				}
				else if (defaultAddons) {
					this._dataTable.doInputSearch(Server.Routes.CheckLists.GetAllAddonsForFilter,
						View41DataParsers.AddonsParser,
						{ _idAdminCompany, isForClient: false, searchKey, searchValue }
					)
				}
			}
			
			// Instalaciones.
			else if (componentName === 'Facilities') {
				if (componentSubName === 'ByStorage') {
					// Constantes para simular seleccion de Instalacion. Se utiliza para mostrar todos y mostrar funcionamiento de EveryThings
					const _idInstallation = 'null'
					const _idStorage = 'null'

					if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.EVERYTHING) {
						this._dataTable.doInputSearch(Server.Routes.CheckLists.GetInstallationsAssociatedFilter,
							View41DataParsers.FacilityAssociationsParser,
							{ _idAdminCompany, _idStorage, _idInstallation, searchKey, searchValue }
						)
					}
					else if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE) {
						this._dataTable.doInputSearch(Server.Routes.CheckLists.GetInstallationsAssociatedFilter,
							View41DataParsers.FacilityAssociationsParser,
							{ _idAdminCompany, _idStorage, _idInstallation, searchKey, searchValue }
						)
					}
					else if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.STORAGES) {
						this._dataTable.doInputSearch(Server.Routes.CheckLists.GetInstallationsAssociatedFilter,
							View41DataParsers.FacilityAssociationsParser,
							{ _idAdminCompany, _idStorage, _idInstallation, searchKey, searchValue }
						)
					}
				}
				else {
					this._dataTable.doInputSearch(Server.Routes.CheckLists.GetAllInstallationFilter,
						View41DataParsers.FacilitiesParser,
						{ _idAdminCompany, searchKey, searchValue: (searchValue?.toLowerCase() === AppValues.Strings.TEXT_FRIENDLY_YES?.toLocaleLowerCase()) || searchValue }
					)
				}
			}
		},

		onMBItemClick: function(selected: number) {
			// Reiniciar la Selección del 'InputButton'.
			const isSelectedEverything = selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.EVERYTHING
			this._dataTable.clearAll()
			this.setStates<View41Ref['states']>({ associationSelection: null, associationValue: '', showInputButton: !isSelectedEverything })
			this._parsePopupTableOnMenuBarSelection(selected)
			this._initDataTable(selected)
		},

		onPTClose: function() {
			this.setStates<View41Ref['states']>({ showPopupTable: false })
		},

		onPTPaginationChanged: async function(page: number) {
			const { componentName } = this.states
			const { selected } = this._menuBar.states

			// Formularios.
			if (componentName === 'Addons') {
				if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE) {
					DevelopmentTools.printWarn('Unimplemented')
				}
			}

			// Instalaciones.
			else if (componentName === 'Facilities') {
				if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE) {
					DevelopmentTools.printWarn('Unimplemented')
				}
			}

			// Valido para ambos casos.
			if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.STORAGES) {
				// Respuesta de la Acción donde se obtienen los Registros.
				const response = await Store.dispatch(`fetchStoragesForPage`, { forceRefresh: true, page })

				// Procesar la información y actualizar el Componente.
				const SummaryParser = DataParsers.Storages.GetSummaryParser(this._popupTable._dataTable.states.stacked)
				const { fields, items, actions } = SummaryParser(response.data)
				this._popupTable._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
			}
		},

		onPTSearchButtonClicked: function(searching: any) {
			const { searchKey, searchValue } = searching
			const { _idAdminCompany } = Store.getters.getStoredUser
			const { componentName, componentSubName } = this.states
			const { selected } = this._menuBar.states

			// Componente y Propiedades.
			const popupDataTable = this._popupTable._dataTable
			const isPopupDataTableStacked = popupDataTable.states.stacked

			// Formularios.
			if (componentName === 'Addons') {
				if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.EVERYTHING) {
					// this._dataTable.doInputSearch(Server.Routes.CheckLists.GetInstallationsAssociatedFilter,
					// 	View41DataParsers.FacilityAssociationsParser,
					// 	{ _idAdminCompany, _idStorage, _idInstallation, searchKey, searchValue }
					// )
					return
				}
				else if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE) {
					popupDataTable.doInputSearch(Server.Routes.CheckLists.GetFullAddonsFilter,
						View41DataParsers.AddonsParser,
						{ _idAdminCompany, searchKey, searchValue }
					)
					return
				}
			}
			
			// Instalaciones.
			else if (componentName === 'Facilities') {
				if (componentSubName === 'ByStorage') {
					// Constantes para simular seleccion de Instalacion. Se utiliza para mostrar todos y mostrar funcionamiento de EveryThings
					const _idInstallation = 'null'
					const _idStorage = 'null'

					if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.EVERYTHING) {
						// this._dataTable.doInputSearch(Server.Routes.CheckLists.GetInstallationsAssociatedFilter,
						// 	View41DataParsers.FacilityAssociationsParser,
						// 	{ _idAdminCompany, _idStorage, _idInstallation, searchKey, searchValue }
						// )
						return
					}
					else if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE) {
						popupDataTable.doInputSearch(Server.Routes.CheckLists.GetAllInstallationFilter,
							View41DataParsers.FacilitiesParser,
							{ _idAdminCompany, searchKey, searchValue: (searchValue?.toLowerCase() === AppValues.Strings.TEXT_FRIENDLY_YES?.toLocaleLowerCase()) || searchValue }
						)
						return
					}
				}
			}

			// Valido para Ambos Casos (Plantas)
			if (selected === Module4.M41.Defaults.MenuBarKeyAssociationsOptions.STORAGES) {
				const SummaryParser = DataParsers.Storages.GetSummaryParser(isPopupDataTableStacked)
				popupDataTable.doInputSearch(Server.Routes.Storages.GetStoragesBySearchFilter,
					SummaryParser,
					{ _idAdminCompany, searchKey, searchValue }
				)
			}
		},

		onPTSelect: function({ item }: any) {
			this.setStates<View41Ref['states']>({ associationSelection: item, associationValue: item.code, showPopupTable: false })
			this._fetchAssociationsByFilter()
		},

		onSMSubItemClick: function(componentName: string, componentSubName: string) {
			const showMenuBar = componentSubName === 'ByStorage'

			// Obtener la asociación de Formularios y Ubicaciones.
			if (componentName === 'Addons' && showMenuBar) {
				this._fetchAddonsAssociations(1, null, null)
			}
			// Obtener la asociación de Instalaciones y Ubicaciones.
			else if (componentName === 'Facilities' && showMenuBar) {
				this._fetchFacilityAssociations(1)
			}

			this.setStates<View41Ref['states']>({ associationSelection: null, associationValue: '', componentName, componentSubName, showInputButton: true, showMenuBar })
			this._parsePopupTableOnMenuBarSelection(Module4.M41.Defaults.MenuBarKeyAssociationsOptions.CODE)
			this._resetAllDataAndValues()
		}
	}
})

// Exports
export default View41
export type View41Ref = InstanceType<typeof View41>