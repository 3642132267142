// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent from '@/Mixins/MixinComponent'

// Component Extend
const FacilityForm = VueMixins(MixinComponent).extend({
	name: 'FacilityForm',

	props: {
		show: Boolean
	},

	data: function() {
		return {
			states: {
				code: '',
				name: ''
			}
		}
	},

	methods: {
		clear: function() {
			this.states = {
				code: '',
				name: ''
			}
		}
	}
})

// Exports
export default FacilityForm
export type FacilityFormRef = InstanceType<typeof FacilityForm>