// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Interfaces
import { Components } from '@/Interfaces/Global/Components'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const SideMenu = VueMixins(MixinComponent, MixinResponsive).extend({
	name: 'SideMenu',

	data: function() {
		return {
			items: undefined as Components.Global.SideMenu.Default['items'],
			states: {
				isMobile: false
			}
		}
	},

	computed: {
		_getSideMenuClass: function() {
			if (this.states.isMobile) return this.getResponsiveClasses
			return Store.getters.getStoredSideMenu.class
		},

		_getSideMenuIsMinimized: function() {
			return Store.getters.getStoredSideMenu.isMinimized
		}
	},

	methods: {
		_clearSelection: function() {
			for (const item of this.items) {
				if (Array.isArray(item.items)) {
					for (const subItem of item.items) subItem.isActive = false
				}
				item.isActive = false
			}
		},

		_getToggleIcon: function() {
			return this._getSideMenuIsMinimized ? 'arrow-alt-circle-right' : 'arrow-alt-circle-left'
		},

		_updateActiveItem: function(item: Components.Global.SideMenu.Item) {
			this._clearSelection()
			item.isActive = true
			if (Array.isArray(item.items) && item.items.length > 0) item.items[0].isActive = true
		},

		_updateSubActiveItem: function(item: Components.Global.SideMenu.Item, subItem: Components.Global.SideMenu.SubItem) {
			this._clearSelection()
			item.isActive = true
			subItem.isActive = true
		},

		initialize: function(sideMenu: Components.Global.SideMenu.Default) {
			const { items } = sideMenu
			this.items = items
		},

		onButtonClick: function(item: Components.Global.SideMenu.Item) {
			DevelopmentTools.printWarn('[SideMenu]:onButtonClick event triggered')
			this._updateActiveItem(item)
			this.$emit('onButtonClick', item?.componentType, item.componentName)
		},

		onSMSubItemClick: function(item: Components.Global.SideMenu.Item, subItem: Components.Global.SideMenu.SubItem) {
			DevelopmentTools.printWarn('[SideMenu]:onSMSubItemClick event triggered')
			this._updateSubActiveItem(item, subItem)
			this.$emit('onSMSubItemClick', item.componentName, subItem.name)
		},

		onSMToggleClick: function() {
			DevelopmentTools.printWarn('[SideMenu]:onSMToggleClick event triggered')
			Store.commit('updateSideMenuIsMinimized', !this._getSideMenuIsMinimized)
		}
	}
})

// Exports
export default SideMenu
export type SideMenuRef = InstanceType<typeof SideMenu>