// Constants
import { AppValues } from '@/Constants/Global/AppValues'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const AddonForm = VueMixins(MixinComponent,MixinResponsive).extend({
	name: 'AddonForm',

	props: {
		show: Boolean
	},

	data: function() {
		return {
			states: {
				addonCode: '',
				addonName: '',
				groupArticle: null,
				isForClient: undefined,
				typeEquipment: null
			}
		}
	},

	computed: {
		_getEquipmentTypes: function(): Array<any> {
			return Object.values(AppValues.EquipmentTypes).sort()
		},

		_getGroupArticles: function(): Array<any> {
			return Object.values(AppValues.ArticleGroups).sort()
		},

		_getIsForClientText: function(): string {
			return this.states.isForClient ? 'Sí' : 'No'
		}
	},

	methods: {
		clear: function() {
			this.states = {
				addonCode: '',
				addonName: '',
				groupArticle: null,
				isForClient: undefined,
				typeEquipment: null
			}
		}
	}
})

// Exports
export default AddonForm
export type AddonFormRef = InstanceType<typeof AddonForm>